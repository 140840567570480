<div class="row my-5" *ngIf="change">
  <div class="col-md-2">
    <div class="mb-3 mt-3">
      <div
        *ngIf="
          change.description.includes('new') ||
            change.description.includes('added');
          then newFeture;
          else improvement
        "
      ></div>
      <ng-template #newFeture>
        <span class="badge me-2 bg-majenta">New feature</span>
      </ng-template>
      <ng-template #improvement>
        <span class="badge me-2 bg-primary">Improvement</span>
      </ng-template>
    </div>
    <time class="h5">{{ change.prettyDate }}</time>
  </div>
  <div class="col-md-10 mb-5">
    <div [innerHTML]="change.html"></div>
    <div class="media mt-5">
      <img
        class="me-3"
        src="https://docs.agenty.com/user_avatar/docs.agenty.com/admin/48/433_2.png"
        class="rounded-circle me-2"
        width="48"
        height="48"
      />
      <div class="media-body text-muted">
        <label class="fw-bold mb-0">Vikash Rathee</label>
        <p>CEO, DaySchedule</p>
      </div>
    </div>
    <hr />
    <a
      class="btn btn-link ps-0"
      target="_blank"
      href="https://dayschedule.com/docs/change-log"
      >See all changes <i class="bi bi-box-arrow-up-right"></i
    ></a>
  </div>
</div>