import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { User } from '../../model/user';
import { ToastrHelper } from '../../helper/toastr-helper';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OtpService } from '../../services/otp.service';
import { OTPPurpose, OtpVerification } from '../../model/otpVerification';
import { Subscription, timer } from 'rxjs';

@Component({
  selector: 'app-confirm-otp',
  templateUrl: './confirm-otp.component.html',
  styleUrls: ['./confirm-otp.component.css'],
})
export class ConfirmOtpComponent implements OnInit {
  @Input() otp: OtpVerification;
  @Input() btnText: string = 'Update';
  @Input() btnClass: string = 'btn-primary';
  @Input() title: string = 'OTP';
  @Input() description: string = '';

  authUser: User;
  loading = false;

  isResendDisabled = true;
  remainingSeconds = 30;
  private countdownSubscription: Subscription | null = null;

  constructor(
    private authService: AuthService,
    private toastr: ToastrHelper,
    public activeModal: NgbActiveModal,
    private otpService: OtpService
  ) {}

  ngOnInit(): void {
    this.authUser = this.authService.getUser();
    // Signup OTP are sent automatically, don't send on load
    if (this.otp.purpose == OTPPurpose.signup) {
      this.startTimer();
    } else {
      this.sendOtp();
    }
  }

  sendOtp() {
    this.otpService.sendOtp(this.otp).subscribe(
      (data) => {},
      (err) => {
        this.toastr.handleError(err);
      }
    );
  }

  verifyOtp() {
    this.loading = true;
    this.otpService.verifyOtp(this.otp).subscribe(
      (data) => {
        this.loading = false;
        this.activeModal.close(data);
      },
      (err) => {
        this.loading = false;
        this.toastr.handleError(err);
      }
    );
  }

  resendOTP(): void {
    this.sendOtp();
    this.startTimer();
    this.toastr.handleSuccess('Email OTP sent');
  }

  startTimer(seconds = 30) {
    this.isResendDisabled = true;
    this.remainingSeconds = seconds;
    if (this.countdownSubscription) {
      this.countdownSubscription.unsubscribe();
    }

    this.countdownSubscription = timer(0, 1000).subscribe((elapsedSeconds) => {
      this.remainingSeconds = seconds - elapsedSeconds;
      if (this.remainingSeconds <= 0) {
        this.isResendDisabled = false;
        this.countdownSubscription?.unsubscribe();
      }
    });
  }

  ngOnDestroy(): void {
    this.countdownSubscription?.unsubscribe();
  }
}
