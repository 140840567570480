import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  AuthenticateRequest,
  ForgotRequest,
  PermissionType,
  ResetPassword,
  RoleType,
  SignupRequest,
} from '../model/models';
import { CookiesService } from 'src/app/services/cookies.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Menu } from '../model/menu';

const TOKEN_KEY = 'auth_token';
const USER_KEY = 'auth_user';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly isCookieSupported: boolean;
  public readonly authPages: Array<string> = [
    '/login',
    '/signup',
    '/forgot-password',
    '/reset-password',
    '/verification',
    '/join',
  ];

  constructor(
    private httpClient: HttpClient,
    private cookieService: CookiesService
  ) {
    this.isCookieSupported = this.cookieService.testCookie();
  }

  checkPermission(menu: Menu, authUser: any) {
    if (!menu.level && !menu.permissions?.length) return true;
    // Staff permissions
    if (menu.level) {
      const requiredLevel = Number(menu.level?.replace('Level', ''));
      const userLevel = Number(authUser.level?.replace('Level', ''));
      return userLevel >= requiredLevel;
    } else if (menu.permissions) {
      if (menu.permissions.includes(authUser.role)) {
        return true;
      }
      const userPermission = authUser.permissions?.split(',');
      if (
        userPermission &&
        menu.permissions.some((x) => userPermission.includes(x))
      ) {
        return true;
      }
    }
    return false;
  }

  login(user: AuthenticateRequest): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/auth/signin', user);
  }

  signup(user: SignupRequest): Observable<any> {
    const response = this.httpClient.post(
      environment.apiBaseUrl + '/auth/signup',
      user
    );
    return response;
  }

  logout(): void {
    if (this.isCookieSupported) {
      this.cookieService.deleteAllCookie();
    } else {
      localStorage.removeItem(TOKEN_KEY);
      localStorage.removeItem(USER_KEY);
    }

    const { pathname } = window.location;
    if (pathname !== '/' && !this.authPages.includes(pathname)) {
      window.location.href = '/';
    }
  }

  getRedirectUrl(returnUrl: string | undefined) {
    const authUser: any = this.getUser();
    if (authUser.is_verified <= 1) {
      return '/onboarding';
    } else {
      if (authUser.role == RoleType.Custom && authUser.permissions) {
        if (authUser.permissions.includes(PermissionType['booking.read'])) {
          return '/bookings';
        } else if (
          authUser.permissions.includes(PermissionType['resource.read'])
        ) {
          return '/resources';
        } else {
          return '/dashboard';
        }
      } else {
        return returnUrl ? decodeURIComponent(returnUrl) : '/bookings';
      }
    }
  }

  public saveToken(token: string): void {
    if (this.isCookieSupported) {
      this.cookieService.deleteCookie(TOKEN_KEY);
      this.cookieService.setCookie(TOKEN_KEY, token);
    } else {
      localStorage.setItem(TOKEN_KEY, token);
    }
  }

  public getToken(): string | null {
    if (this.isCookieSupported) {
      return this.cookieService.getCookie(TOKEN_KEY);
    } else {
      return localStorage.getItem(TOKEN_KEY);
    }
  }

  public saveUser(user: any): void {
    if (this.isCookieSupported) {
      this.cookieService.deleteCookie(USER_KEY);
      this.cookieService.setCookie(USER_KEY, JSON.stringify(user));
    } else {
      localStorage.setItem(USER_KEY, JSON.stringify(user));
    }
  }

  public getUser(): any {
    let user = null;
    if (this.isCookieSupported) {
      user = this.cookieService.getCookie(USER_KEY);
    } else {
      user = localStorage.getItem(USER_KEY);
    }

    if (user) {
      try {
        const authUser = JSON.parse(user);
        return {
          ...authUser,
          trust: authUser.level
            ? Number(authUser.level.replace('Level', ''))
            : 0,
        };
      } catch (e) {}
    }
    return null;
  }

  changeUserPassword(user: any) {
    return this.httpClient.post(
      environment.apiBaseUrl + `/auth/change-password`,
      user
    );
  }

  verifyUser(token: string): Observable<any> {
    return this.httpClient.get(
      environment.apiBaseUrl + `/auth/verify-user?token=${token}`
    );
  }

  unSubscribeUserEmail(email: string, uid: string) {
    return this.httpClient.put(
      environment.apiBaseUrl + `/unsubscribe?email=${email}&uid=${uid}`,
      {}
    );
  }

  joinAccount(
    token: string,
    passwordResetRequest: ResetPassword
  ): Observable<any> {
    return this.httpClient.post(
      environment.apiBaseUrl + `/auth/join?token=${token}`,
      passwordResetRequest
    );
  }

  forgotPassword(email: ForgotRequest): Observable<any> {
    return this.httpClient.post(
      environment.apiBaseUrl + '/auth/forgot-password',
      email
    );
  }

  changePassword(token: string, user: ResetPassword): Observable<any> {
    return this.httpClient.post(
      environment.apiBaseUrl + `/auth/reset-password`,
      {
        ...user,
        token: token,
      }
    );
  }

  verifyAccountOtp(otp: string) {
    return this.httpClient.get(
      environment.apiBaseUrl + `/auth/signup?otp=${otp}`
    );
  }

  sendOtp(otp: string) {
    return this.httpClient.get(
      environment.apiBaseUrl + `/auth/change-password?otp=${otp}`
    );
  }
}
